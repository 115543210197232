import { useContext, useEffect, useMemo, useState } from 'react'
import { isAfter } from 'date-fns/isAfter'
import capitalize from 'lodash/capitalize'
import { rgba } from 'polished'
import styled from 'styled-components'
import { useTheme } from 'styled-components'

import { Disclaimer } from 'bl-common/src/booking/Disclaimer/Disclaimer'
import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { ProgressButton } from 'bl-common/src/elements/ProgressButton/ProgressButton'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'
import { FlowComponent, FlowControl } from 'bl-flows-core'
import {
  AdmissionItem,
  CartItemType,
  CartType,
  TransportationItem,
} from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'
import {
  displayDate,
  formatDateInUTC,
} from 'bl-utils/src/formatting/formatDate'
import { getMembershipProductId } from 'bl-utils/src/membership'
import {
  PRODUCT_IDS,
  ProductId,
  productIdToAdmissionType,
} from 'bl-utils/src/ProductIds'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { timeMessages } from '.././messages/time'
import { commonMessages } from '../messages/common'
import { getAdmissionTitleFromProductId } from '../utils'
import { getEcommerce } from '../utils/getEcommerce'

const DrawerWrapper = styled.div<{ showUpsell?: boolean }>(
  ({ showUpsell }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'stretch',
    flexDirection: 'column',
    rowGap: theme.spacing[3],

    [mediaObj.md]: {
      justifyContent: showUpsell ? 'space-between' : 'center',
      flexDirection: 'row',
    },
  })
)

const ConfirmationTitle = styled.span({
  display: 'inline-block',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
})

const Confirmation = styled.div<{ showUpsell?: boolean }>(({ showUpsell }) => ({
  paddingLeft: theme.spacing[4],
  paddingRight: theme.spacing[4],
  width: '100%',
  [mediaObj.md]: {
    flex: showUpsell ? '0 1 50%' : '0 1 60%',
    paddingRight: theme.spacing[2],
  },

  [mediaObj.bmd]: {
    paddingRight: theme.spacing[3],
  },

  button: {
    width: '100%',
  },
}))
const Upsell = styled.div({
  paddingLeft: theme.spacing[4],
  paddingRight: theme.spacing[4],
  width: '100%',

  [mediaObj.md]: {
    paddingLeft: theme.spacing[2],
    flex: '0 1 50%',
  },

  [mediaObj.bmd]: {
    paddingLeft: theme.spacing[3],
  },
})

const Summary = styled.div<{
  themeStyle?: PartialBookingEngine['confirmationDrawer']
}>(({ themeStyle }) => ({
  paddingTop: theme.spacing[1],
  paddingBottom: theme.spacing[1],
  borderTop: `1px solid ${rgba(
    themeStyle?.summaryBorderColor ?? colors.midGrey,
    0.2
  )}`,
  borderBottom: `1px solid ${rgba(
    themeStyle?.summaryBorderColor ?? colors.midGrey,
    0.2
  )}`,
  marginTop: theme.spacing[2],
  marginBottom: theme.spacing[1.5],

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[0.5],
}))

const SummaryRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing[0.5],
})

const SummaryPrices = styled.div({
  display: 'flex',
  gap: theme.spacing[2],

  [mediaObj.md]: {
    gap: theme.spacing[1],
  },

  [mediaObj.bmd]: {
    gap: theme.spacing[2],
  },
})

const InfoText = styled.div({
  [mediaObj.md]: {
    height: theme.spacing[2.5],
  },
  [mediaObj.lg]: {
    height: 'auto',
  },
})

const productIdAdults = {
  retreat: PRODUCT_IDS.SpaRetreat,
  comfort: PRODUCT_IDS.SpaComfort,
  premium: PRODUCT_IDS.SpaPremium,
}

const triggerFunnelTimeEvent = (
  control: FlowControl,
  isPremiumUpgrade: boolean
) => {
  const flowState = control.flow.state
  const isRetreatSpaFlow = control.flow.setupHook?.isRetreatSpaFlow

  triggerEvent({
    event: 'funnel_time',
    BLType: isRetreatSpaFlow ? 'Day Visit Spa' : 'Day Visit',
    pageTitle: 'Book Time',
    pageCategory: 'book',
    Adult: flowState.guests.adults,
    ...(!isRetreatSpaFlow && { Children: flowState.guests.children }),
    Calendar: formatDateInUTC(
      new Date(flowState.calendar.arrivalDate),
      'yyyy-MM-dd'
    ),
    Package: isRetreatSpaFlow
      ? 'retreat spa'
      : isPremiumUpgrade
      ? 'premium'
      : control.flow.setupHook?.selectedPackage,
    Time: getTimeFromDateTimeString(control.screen.state?.selectedTime),
  })
}

export const ConfirmationDrawer: React.FC<FlowComponent> = ({
  control,
  screenTheme,
}) => {
  const confirmationDrawerProductId =
    control.flow?.setupHook?.confirmationDrawerProductId
  const admissionType =
    productIdToAdmissionType[confirmationDrawerProductId || 'comfort']
  const [isUpgrading, setIsUpgrading] = useState(false)
  const selectedPrice = control.screen?.stateRef?.current?.selectedPrice
  const spaType = control.flow.setupHook?.selectedPackage as
    | 'retreat'
    | 'comfort'
    | 'premium'
    | 'subscription'
  const adults = control.flow.state?.guests?.adults
  const changingRooms = control.flow.state?.guests?.changingRooms
  const children = control.flow.state?.guests?.children
  const productId = control.screen.stateRef?.current?.productId

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.confirmationDrawer

  const premiumAvailability =
    control.screen.stateRef?.current?.premiumAvailability

  const showUpsell =
    (confirmationDrawerProductId === PRODUCT_IDS.SpaComfort &&
      !!premiumAvailability) ||
    isUpgrading
  const upgradePrice = premiumAvailability?.price - selectedPrice
  const totalUpgradePrice =
    premiumAvailability?.price * control.flow.state?.guests?.adults

  const { mediaMd } = useBreakpoints()
  const { currency, exchangeRates } = useContext(CurrencyContext)
  const { cartId, addProductCartItem, cart, removeItemFromCart, createCart } =
    useCartContext()
  const [isConfirming, setIsConfirming] = useState(false)
  const isRetreatSpa = productIdAdults[spaType] === PRODUCT_IDS.SpaRetreat
  const numberOfRetreatChangingRooms = changingRooms || Math.ceil(adults / 2)

  const isSignedInWithMembership = cart.membership?.membershipToken != null

  let membershipProductId: string | null = null

  if (isSignedInWithMembership) {
    membershipProductId = getMembershipProductId(
      control.flow.setupHook?.cart?.membership
    )
  }

  useEffect(() => {
    if (!isConfirming) {
      const selectedProductId =
        spaType === 'subscription' ? membershipProductId : productId
      triggerEvent({
        event: 'view_item',
        ecommerce: getEcommerce({
          productId: selectedProductId,
          price: selectedPrice,
          adults: isRetreatSpa ? numberOfRetreatChangingRooms : adults,
          children,
          exchangeRates,
          spaType,
        }),
      })
      triggerEvent({
        event: 'select_item',
        ecommerce: getEcommerce({
          productId: selectedProductId,
          price: selectedPrice,
          adults: isRetreatSpa ? numberOfRetreatChangingRooms : adults,
          children,
          exchangeRates,
          spaType,
        }),
      })
    }
  }, [spaType, selectedPrice, exchangeRates, adults, children, isConfirming])

  const handleContinue = async (isPremiumUpgrade?: boolean) => {
    const previousAdmissionItems = cart?.items?.filter(
      item => item.type === CartItemType.Admission
    ) as AdmissionItem[]
    const previousActivityItems = cart?.items?.filter(
      item =>
        item.type === CartItemType.Massage ||
        item.type === CartItemType.Restaurant
    )
    const previousTransferItem = cart?.items?.find(
      item => item.type === CartItemType.Transfer
    )
    const previousProductItem = cart?.items?.find(
      item => item.type === CartItemType.Item
    )
    const hasItemsInCart = !!previousAdmissionItems?.length

    const allActivitiesAfterSelectedTime =
      !!previousActivityItems &&
      previousActivityItems?.every(item =>
        isAfter(
          new Date((item as AdmissionItem).meta?.arrivalTime),
          new Date(control.screen.stateRef?.current?.selectedTime)
        )
      )

    const selectedTimeIsBetweenTransferItems =
      previousTransferItem &&
      isAfter(
        new Date(control.screen.stateRef?.current?.selectedTime),
        new Date(
          (
            previousTransferItem as TransportationItem
          )?.meta?.inboundDepartureTime
        )
      ) &&
      isAfter(
        new Date(
          (
            previousTransferItem as TransportationItem
          )?.meta?.outboundDepartureTime
        ),
        new Date(control.screen.stateRef?.current?.selectedTime)
      )

    const previousGuests = previousAdmissionItems?.reduce(
      (acc, curr) => {
        if (curr.productId === PRODUCT_IDS.SpaChild) {
          acc.prevChildren += curr.qty
        } else {
          acc.prevAdults += curr.qty
        }
        return acc
      },
      { prevAdults: 0, prevChildren: 0 }
    )

    const hasSameGuests =
      previousGuests?.prevAdults === adults &&
      previousGuests?.prevChildren === children

    const hasSameArrivalDate =
      hasItemsInCart &&
      new Date(control.flow.stateRef.current.calendar?.arrivalDate).setHours(
        0,
        0,
        0
      ) ===
        new Date(previousAdmissionItems?.[0]?.meta?.arrivalTime).setHours(
          0,
          0,
          0
        )
    const hasSameSelectedPackage =
      hasItemsInCart &&
      confirmationDrawerProductId === previousAdmissionItems?.[0]?.productId
    const hasSameSelectedTime =
      hasItemsInCart &&
      getTimeFromDateTimeString(
        control.screen.stateRef.current?.selectedTime
      ) ===
        formatDateInUTC(
          new Date(previousAdmissionItems?.[0]?.meta?.arrivalTime),
          'HH:mm'
        )

    const hasAdultAdmission = adults > 0
    const hasChildrenAdmission = children > 0

    setIsConfirming(true)

    // this is needed to disable validation on the SelectDayVisitTimeField
    control.screen.setUiState({
      isAddingToCart: true,
    })

    if (!hasAdultAdmission) {
      setIsConfirming(false)
      return false
    }

    if (isPremiumUpgrade) {
      setIsUpgrading(true)
    }

    let updatedCartId = cartId

    // if date, package and time are all the same as the items in the cart, we don't need to reset the cart and add it again
    if (
      hasSameGuests &&
      hasSameArrivalDate &&
      hasSameSelectedPackage &&
      hasSameSelectedTime &&
      !isPremiumUpgrade
    ) {
      return true
    }

    try {
      if (cart?.items?.length) {
        // if the date and package are the same as before, but the time is different, we need to check if there are other activities/transfers in the cart

        const shouldKeepActivities =
          (!!previousActivityItems.length && allActivitiesAfterSelectedTime) ||
          (!!previousTransferItem && selectedTimeIsBetweenTransferItems) ||
          !!previousProductItem

        // if there are activities/transfers in the cart, and the new time is between the activities/transfers,
        // we should remove the admissionItems from the cart and add them again, rather than resetting the cart
        if (
          hasSameArrivalDate &&
          hasSameSelectedPackage &&
          shouldKeepActivities
        ) {
          try {
            for (let i = 0; i < previousAdmissionItems.length; i++) {
              // Don't refetch until we remove the last item
              const isLastItem = i === previousAdmissionItems.length - 1
              await removeItemFromCart(
                previousAdmissionItems[i].id,
                !isLastItem
              )
            }
          } catch (error) {
            sentryLogging({
              error: new Error('Error removing activities from cart', error),
            })
          }
        } else if (isSignedInWithMembership) {
          // Remove items from cart
          // We don't want to reset the cart if the user has signed in with a membership
          // card, because then the user needs to sign in again.
          for (let i = 0; i < cart.items.length; i++) {
            // Don't refetch until we remove the last item
            const isLastItem = i === cart.items.length - 1
            await removeItemFromCart(cart.items[i].id, !isLastItem)
          }
        } else {
          // Re-create the cart. Please note that we should never re-create the cart
          // when signed in with membership card. Otherwise the user will no longer be signed in.
          const newCart = await createCart({ force: true })
          updatedCartId = newCart.id
        }
      }

      const selectedProductId = (
        isPremiumUpgrade
          ? premiumAvailability?.productId
          : confirmationDrawerProductId
      ) as ProductId

      triggerEvent({
        event: 'add_to_cart',
        ecommerce: getEcommerce({
          productId: selectedProductId,
          price: isPremiumUpgrade ? premiumAvailability?.price : selectedPrice,
          adults: isRetreatSpa ? numberOfRetreatChangingRooms : adults,
          children,
          exchangeRates,
          spaType,
        }),
      })

      if (isRetreatSpa) {
        for (let i = 0; i < numberOfRetreatChangingRooms; i++) {
          await addProductCartItem(
            {
              cartId: updatedCartId,
              productId: selectedProductId,
              qty: 1,
              type: CartType.Dayspa,
              meta: {
                noOfPersons:
                  numChangingRoomsNotFull !== 0 && i < numChangingRoomsNotFull
                    ? 1
                    : 2,
                arrivalTime: control.screen.stateRef?.current?.selectedTime,
                offerId: isPremiumUpgrade
                  ? premiumAvailability.offerId
                  : control.screen.stateRef?.current?.offerId,
              },
            },
            i < numberOfRetreatChangingRooms - 1
          ) // Skip re-fetching the cart if we have more changing rooms to add
        }
      } else {
        await addProductCartItem(
          {
            cartId: updatedCartId,
            productId: selectedProductId,
            qty: isRetreatSpa ? numberOfRetreatChangingRooms : adults,
            type: CartType.Dayspa,
            meta: {
              arrivalTime: control.screen.stateRef?.current?.selectedTime,
              offerId: isPremiumUpgrade
                ? premiumAvailability.offerId
                : control.screen.stateRef?.current?.offerId,
            },
          },
          hasChildrenAdmission // Skip re-fetching the cart if we have children to add
        )

        if (hasChildrenAdmission) {
          await addProductCartItem({
            cartId: updatedCartId,
            productId: PRODUCT_IDS.SpaChild,
            qty: children,
            type: CartType.Dayspa,
            meta: {
              arrivalTime: control.screen.stateRef?.current?.selectedTime,
              offerId: control.screen.stateRef?.current?.childOfferId,
            },
          })
        }

        if (isPremiumUpgrade) {
          control.flow.setupHook?.changePackageSlug('premium')
        }
      }

      return true
    } catch (err) {
      await control.confirm(
        control.context.t(timeMessages.errors.addAdmissionDescription),
        {
          confirmLabel: control.context.t(commonMessages.info.modalContinue),
          title: control.context.t(timeMessages.errors.addAdmissionTitle),
        }
      )
      sentryLogging({
        error: new Error('Error adding spa items to cart', err),
      })
      setIsConfirming(false)
    }

    return false
  }

  const title = useMemo(() => {
    if (control.screen.state?.selectedTime) {
      return control.context.t(timeMessages.info.drawerTitle, {
        date: displayDate(new Date(control.screen.state.selectedTime), {
          locale: control.context.locale,
          withWeekday: true,
          displayLongWeekday: true,
          displayLongMonth: true,
        }),
        time: formatDateInUTC(
          new Date(control.screen.state.selectedTime),
          'HH:mm',
          control.context.locale
        ),
      })
    }

    return 'No date'
  }, [control.screen.state])

  const admissionTitle = getAdmissionTitleFromProductId(
    control,
    confirmationDrawerProductId
  )
  const drawerSummary = control.context.t(
    timeMessages.info.drawerSummaryAdultMobile,
    { adults }
  )
  const titleText = mediaMd
    ? `${admissionTitle}, ${drawerSummary}`
    : drawerSummary

  // Used to determine how many changing rooms are full (2 guests per changing room)
  const numChangingRoomsNotFull = numberOfRetreatChangingRooms * 2 - adults

  return (
    <DrawerWrapper showUpsell={showUpsell}>
      <Confirmation showUpsell={showUpsell}>
        <Type preset="subtitle" weight="bold">
          <ConfirmationTitle>{title}</ConfirmationTitle>
        </Type>
        <Summary themeStyle={themeStyle}>
          {isRetreatSpa ? (
            <>
              {Array.from({ length: numberOfRetreatChangingRooms }).map(
                (_, index) => (
                  <SummaryRow key={index}>
                    <Type preset="text">{`${
                      mediaMd ? `${admissionTitle} ` : ''
                    }${control.context.t(
                      timeMessages.info.drawerSummaryAdultMobile,
                      {
                        adults:
                          numChangingRoomsNotFull !== 0 &&
                          index < numChangingRoomsNotFull
                            ? 1
                            : 2,
                      }
                    )}`}</Type>

                    <SummaryPrices>
                      <Type preset="text" opacity={0.5}>
                        1 x{' '}
                        {control.context.t(
                          timeMessages.info.drawerSummaryChangingRoom
                        )}
                      </Type>
                      <Type
                        preset="text"
                        color={themeStyle?.totalPriceColor ?? colors.deepBlue}
                        weight="medium"
                      >
                        {formatPrice(
                          calcPrice(selectedPrice, exchangeRates[currency]),
                          currency,
                          false
                        )}
                      </Type>
                    </SummaryPrices>
                  </SummaryRow>
                )
              )}
            </>
          ) : (
            <SummaryRow>
              <Type preset="text">{titleText}</Type>
              <SummaryPrices>
                <Type preset="text" opacity={0.5}>{`${adults} x ${formatPrice(
                  calcPrice(selectedPrice, exchangeRates[currency]),
                  currency,
                  false
                )}`}</Type>
                <Type
                  preset="text"
                  color={themeStyle?.totalPriceColor ?? colors.deepBlue}
                  weight="medium"
                >
                  {formatPrice(
                    calcPrice(selectedPrice, exchangeRates[currency]) * adults,
                    currency,
                    false
                  )}
                </Type>
              </SummaryPrices>
            </SummaryRow>
          )}

          {children > 0 && (
            <SummaryRow>
              <Type preset="text">
                {mediaMd
                  ? control.context.t(timeMessages.info.drawerSummaryChildren, {
                      package: capitalize(spaType),
                      children,
                    })
                  : control.context.t(
                      timeMessages.info.drawerSummaryChildrenMobile,
                      {
                        children,
                      }
                    )}
              </Type>
              <Type preset="text" color={colors.deepBlue} weight="medium">
                {control.context.t(timeMessages.info.drawerFreeLabel)}
              </Type>
            </SummaryRow>
          )}
        </Summary>
        <InfoText>
          <Type preset={mediaMd ? 'textSmall' : 'textTiny'}>
            {control.context.t(
              isRetreatSpa
                ? timeMessages.info.drawerRetreatDisclaimer
                : timeMessages.info.drawerDisclaimer
            )}
          </Type>
        </InfoText>
        <ProgressButton
          onClick={() => handleContinue(false)}
          onComplete={() => {
            triggerFunnelTimeEvent(control, false)
            // If we are changing from one package to another, we need to reflect it in the slug
            if (admissionType !== control.flow.setupHook?.selectedPackage) {
              control.flow.setupHook?.changePackageSlug?.(admissionType)
            }
            control.nextScreen()
          }}
          style={{ marginTop: theme.spacing[mediaMd ? 1 : 1.5] }}
          disabled={isConfirming}
        >
          {showUpsell
            ? control.context.t(timeMessages.info.drawerContinueComfortButton)
            : control.context.t(timeMessages.info.drawerConfirmButton)}
        </ProgressButton>
      </Confirmation>
      {showUpsell && (
        <Upsell>
          <Disclaimer>
            <Type
              preset={mediaMd ? 'textLarge' : 'text'}
              weight="bold"
              mBottom={0.5}
            >
              {control.context.t(timeMessages.info.drawerUpsellTitle, {
                price: formatPrice(
                  calcPrice(upgradePrice, exchangeRates[currency]),
                  currency,
                  false
                ),
              })}
            </Type>
            <Type preset="text">
              {control.context.t(timeMessages.info.drawerUpsellText)}
            </Type>
          </Disclaimer>
          <InfoText>
            <Type preset="textTiny" mTop={1}>
              {control.context.t(timeMessages.info.drawerUpsellDisclaimer)}
            </Type>
          </InfoText>
          <ProgressButton
            preset="transparentWithBlueBorder"
            onClick={() => handleContinue(true)}
            onComplete={() => {
              triggerFunnelTimeEvent(control, true)
              // If we are changing from one package to another, we need to reflect it in the slug
              if (admissionType !== control.flow.setupHook?.selectedPackage) {
                control.flow.setupHook?.changePackageSlug?.(admissionType)
              }
              control.nextScreen()
            }}
            style={{ width: '100%', marginTop: theme.spacing[1] }}
            disabled={isConfirming}
          >
            {mediaMd
              ? control.context.t(timeMessages.info.drawerUpsellButton, {
                  price: formatPrice(
                    calcPrice(totalUpgradePrice, exchangeRates[currency]),
                    currency,
                    false
                  ),
                })
              : control.context.t(timeMessages.info.drawerUpsellButtonMobile)}
          </ProgressButton>
        </Upsell>
      )}
    </DrawerWrapper>
  )
}
