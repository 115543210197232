import { useEffect, useState } from 'react'
import { Formik } from 'formik'

import { ProgressButton } from '../../elements/ProgressButton/ProgressButton'
import { Type } from '../../elements/Typography/Typography'
import { ErrorMessage } from '../../form/ErrorMessage/ErrorMessage'
import { ErrorFocus } from './ErrorFocus'
import { widgets } from './widgets'

export const SimpleForm = ({
  form,
  buttonText,
  buttonSize = 'large' as 'small' | 'medium' | 'large' | 'menu',
  initialValues,
  validate,
  onSubmit,
  onComplete,
  disclaimer = undefined,
  serverError = undefined, // displayed above the form
  parentErrors = undefined, // displayed at the field level
  focusFirstInput = undefined,
  validateOnBlur = true,
}) => {
  const [isComplete, setComplete] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const delayedSubmit = async values => {
    if (submitting) {
      return
    }
    setSubmitting(true)
    const shouldContinue = await onSubmit(values)

    if (!shouldContinue) {
      setSubmitting(false)
      return
    }

    setComplete(true)
    setTimeout(() => {
      setSubmitting(false)
      onComplete()
    }, 300)
  }

  useEffect(() => {
    if (focusFirstInput) {
      // Added slight timeout because the form could take a moment to render
      // Since it usually shows up in an animated drawer
      setTimeout(() => {
        const firstInputId =
          form && form.fields.length > 0
            ? form.fields[0].key
            : form.fields[0].name ?? ''
        const firstInput = document.getElementById(firstInputId)

        if (firstInput) {
          firstInput.focus()
        }
      }, 200)
    }
  }, [focusFirstInput])

  return (
    <Formik
      onSubmit={delayedSubmit}
      validate={validate}
      initialValues={initialValues}
      validateOnBlur={validateOnBlur}
    >
      {({
        handleSubmit,
        submitCount,
        errors: _errors,
        touched,
        isValidating,
        isSubmitting,
        values,
      }) => {
        const errors = { ..._errors, ...parentErrors }
        return (
          <>
            <ErrorMessage
              error={!!serverError}
              errorMessage={serverError && serverError.message}
            />
            <form onSubmit={handleSubmit}>
              <ErrorMessage
                error={!!serverError}
                errorMessage={serverError && serverError.message}
              />
              {form.fields.map(field => {
                if (field.component) {
                  const Comp = field.component
                  return <Comp key={field.key} />
                }

                const Widget = widgets[field.type]

                return Widget ? (
                  <Widget
                    key={field.key}
                    field={field}
                    hasTriedSubmitting={submitCount > 0}
                    errors={errors}
                    touched={touched}
                    isHidden={field.isHidden}
                    values={values}
                    onEnterKeyPress={handleSubmit}
                  />
                ) : null
              })}
              <ErrorFocus
                errors={errors}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <ProgressButton
                type="submit"
                loading={submitting}
                complete={isComplete}
                display="block"
                paddingSize={buttonSize}
                top={{ xs: 2 }}
                bottom={{ xs: 0.5 }}
                style={{ width: '100%' }}
              >
                {buttonText || 'Submit'}
              </ProgressButton>
              {disclaimer && <Type preset="textSmall">{disclaimer}</Type>}
            </form>
          </>
        )
      }}
    </Formik>
  )
}
