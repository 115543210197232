import { Entry } from 'contentful'

import { IBeSpaFlowFields } from 'bl-common/src/generated/contentful'
import { NewsletterSignup } from 'bl-common/src/hooks/useMailListSignup'
import { FlowControl, FlowState } from 'bl-flows-core'
import { Cart, CartItemType } from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import {
  DayVisitProductId,
  HB_PRODUCT_IDS,
  PRODUCT_IDS,
  productIdToAdmissionType,
} from 'bl-utils/src/ProductIds'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { productIdsMessages } from '../../../messages'
import { getSpaBookingItems } from '../../../utils/get-analytics-items'
import { admissionMessages } from '../messages/admission'
import { timeMessages } from '../messages/time'
import { orderAdmissions } from './ordered-admissions'

export const calculateNumberOfGuests = (guests: {
  adults?: number
  children?: number
  youngAdults?: number
}) =>
  (guests?.adults ?? 0) + (guests?.children ?? 0) + (guests?.youngAdults ?? 0)

export type ConfirmationPaths = {
  en: string
  is: string
}

const navigateToConfirmation = (
  control: FlowControl,
  confirmationPaths: ConfirmationPaths
) => {
  const confirmationPath = confirmationPaths[control.context.locale]
  control.flow.setupHook?.router.push(confirmationPath)
}

export const extractCustomerData = (flowState: FlowState) => ({
  ...flowState?.information?.primaryGuest,
  nationality: flowState?.information?.nationality,
  newsletter: flowState?.information?.receiveNewsLetter,
})

export const getAdmissionTitleFromProductId = (
  control: FlowControl,
  productId: DayVisitProductId,
  isShortTitle?: boolean
) => {
  const admissionType = productIdToAdmissionType[productId]

  if (admissionType == null) {
    return ''
  }

  return getAdmissionTitle(control, admissionType, isShortTitle)
}

export const getAdmissionTitle = (
  control: FlowControl,
  admissionType: string,
  isShortTitle?: boolean
) => {
  const admissionTitles = {
    retreat: productIdsMessages[PRODUCT_IDS.SpaRetreat],
    comfort: productIdsMessages[PRODUCT_IDS.SpaComfort],
    premium: productIdsMessages[PRODUCT_IDS.SpaPremium],
    signature: productIdsMessages[PRODUCT_IDS.SpaSignature],
    subscription: timeMessages.info.subscriptionAdmissionTitle,
    wintercard: timeMessages.info.subscriptionAdmissionTitle,
    summercard: timeMessages.info.subscriptionAdmissionTitle,
    highlandbaseBaths: productIdsMessages[HB_PRODUCT_IDS.AdultAdmission],
  }

  const admissionShortTitles = {
    retreat: admissionMessages.info.retreat,
    comfort: admissionMessages.info.comfort,
    premium: admissionMessages.info.premium,
    signature: admissionMessages.info.signature,
    subscription: admissionMessages.info.subscription,
    wintercard: admissionMessages.info.subscription,
    summercard: admissionMessages.info.subscription,
    highlandbaseBaths: admissionMessages.info.highlandbaseBaths,
  }

  if (isShortTitle) {
    if (admissionShortTitles[admissionType || 'comfort'] == null) {
      return ''
    }

    return control.context.t(admissionShortTitles[admissionType || 'comfort'])
  }

  if (admissionTitles[admissionType || 'comfort'] == null) {
    return ''
  }

  return control.context.t(admissionTitles[admissionType || 'comfort'])
}

export const completeAndNavigate = (
  control: FlowControl,
  confirmationPaths: ConfirmationPaths
) => {
  control.completeFlow()
  navigateToConfirmation(control, confirmationPaths)
}

const updateCart = async ({
  control,
  bookingNumber,
  customer,
  flowState,
}: {
  control: FlowControl
  bookingNumber: string
  customer: FlowState
  flowState: FlowState
}) => {
  await control.flow.setupHook?.setPreviousConfirmedCart({
    ...control.flow.setupHook.cart,
    package: control.flow.setupHook?.selectedPackage || 'comfort',
    bookingNr: bookingNumber,
    primaryGuest: {
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneNo: customer.phone,
    },
    // if there is tranportation on the booking, add info to be displayed on the confirmation screen
    ...(control.flow.setupHook.cart?.items?.find(
      item => item.type === CartItemType.Transfer
    ) && {
      transportationInfo: {
        dropoffArea: flowState?.extrasTransportation?.dropoffArea,
        dropoffLocationName:
          flowState?.extrasTransportation?.dropoffLocationName,
        pickupArea: flowState?.extrasTransportation?.pickupArea,
        pickupLocationName: flowState?.extrasTransportation?.pickupLocationName,
      },
    }),
  })
}

export const finalizeBookingWithoutPayment = async ({
  control,
  newsletterSignup,
}: {
  control: FlowControl
  bookingNumber?: string
  newsletterSignup?: NewsletterSignup
}) => {
  const bookingNumber = await finalizeBooking(control)

  if (!bookingNumber) {
    return false
  }

  await completeBooking({
    control,
    bookingNumber,
    newsletterSignup,
  })

  return true
}

export const finalizeBooking = async (control: FlowControl) => {
  try {
    const flowControl = control.flow
    const flowState = flowControl.stateRef.current || flowControl.state
    const customer = extractCustomerData(flowState)

    const result = await flowControl.setupHook?.checkoutPayment({
      cartId: flowControl.setupHook?.cartId,
      customer,
      paymentId: control.screen.state?.transactionDetails?.paymentId || 0,
      transactionId:
        control.screen.state?.transactionDetails?.transactionId || null,
    })

    return result?.checkoutPayment?.bookingNumber
  } catch {
    // We don't really care if the checkoutPayment fails
    // because we are using the check polling to follow the status of the booking
    return null
  }
}

const triggerPurchaseEvent = (control: FlowControl) => {
  const cart = control.flow.setupHook?.cart as Cart
  const price = cart?.paymentAmount ?? cart?.totalAmount
  const exchangeRates = control.flow.setupHook?.exchangeRates
  const orderedAdmissions = orderAdmissions(cart)
  const analyticsItems = getSpaBookingItems(
    orderedAdmissions,
    exchangeRates,
    cart?.promoCode ?? ''
  )

  try {
    triggerEvent({
      event: 'purchase',
      ecommerce: {
        transaction_id: control.screen.stateRef?.current?.bookingNumber,
        value: price ? calcPrice(price, exchangeRates?.EUR) : 0,
        currency: 'EUR',
        coupon: cart?.promoCode ?? '',
        items: analyticsItems,
      },
    })
  } catch (err) {
    sentryLogging({
      error: new Error('Error triggering purchase Google Analytics event', err),
    })
  }
}

export const completeBooking = async ({
  control,
  bookingNumber,
  newsletterSignup,
  paymentSuccessCallback,
}: {
  control: FlowControl
  bookingNumber: string
  newsletterSignup?: NewsletterSignup
  paymentSuccessCallback?: () => void | Promise<void>
}) => {
  const flowControl = control.flow
  const flowState = flowControl.stateRef.current || flowControl.state
  const customer = extractCustomerData(flowState)
  await updateCart({
    control,
    bookingNumber,
    customer,
    flowState,
  })

  const primaryGuest = flowState?.information?.primaryGuest
  const receiveNewsLetter = flowState?.information?.receiveNewsLetter
  if (newsletterSignup && receiveNewsLetter && primaryGuest) {
    try {
      await newsletterSignup({
        email: primaryGuest.email,
        firstName: primaryGuest.firstName,
        lastName: primaryGuest.lastName,
        $consent: ['email'],
      })
    } catch (err) {
      sentryLogging({
        error: new Error('Error signing up for newsletter', err),
      })
    }
  }

  sentryLogging({
    message: `SPA cart booking completed: ${bookingNumber}`,
    tags: {
      flow: flowControl.setupHook?.selectedPackage,
      cardType:
        control.screen?.state?.cardInfo?.brand ||
        control.screen?.state?.paymentMethod ||
        'Unknown',
    },
  })

  // We should migrate triggerPurchaseEvent to paymentSuccessCallback to
  // avoid having to do Blue Lagoon and Highland Base specific checks
  if (paymentSuccessCallback) {
    paymentSuccessCallback?.()
  } else {
    triggerPurchaseEvent(control)
  }
}

export const completeBookingAndNavigate = async ({
  control,
  confirmationPaths,
  bookingNumber,
  newsletterSignup,
  paymentSuccessCallback,
}: {
  control: FlowControl
  confirmationPaths: ConfirmationPaths
  bookingNumber: string
  newsletterSignup?: NewsletterSignup
  paymentSuccessCallback?: () => void | Promise<void>
}) => {
  await completeBooking({
    control,
    bookingNumber,
    newsletterSignup,
    paymentSuccessCallback,
  })

  completeAndNavigate(control, confirmationPaths)
}

export interface State {
  guests: {
    adults: number
    children?: number
  }
  admission?: {
    selectedPackage?: string
  }
  calendar: {
    arrivalDate: Date
  }
  package: {
    selectedPackage: {
      package: string
      time: string
      price: string
    }
  }
}

export const getSpaFlowSettingsByAdmissionType = (
  admissionType: 'comfort' | 'premium' | 'retreat' | 'highlandbasebaths',
  spaFlowSettingsData: Entry<IBeSpaFlowFields>[]
) => {
  return spaFlowSettingsData?.find(
    flowSettings => flowSettings?.fields?.admissionType === admissionType
  )
}

export const getSpaSummerAndWinterCardDateRange = (
  spaFlowSettingsData: Entry<IBeSpaFlowFields>[]
) => {
  const comfortFlowSettings = getSpaFlowSettingsByAdmissionType(
    'comfort',
    spaFlowSettingsData
  )
  const premiumFlowSettings = getSpaFlowSettingsByAdmissionType(
    'premium',
    spaFlowSettingsData
  )

  return {
    summerCardDateRange:
      comfortFlowSettings?.fields?.summerCardDateRange ||
      premiumFlowSettings?.fields?.summerCardDateRange,
    winterCardDateRange:
      comfortFlowSettings?.fields?.winterCardDateRange ||
      premiumFlowSettings?.fields?.winterCardDateRange,
  }
}

export const getSpaComfortOrPremiumMetadata = (
  spaFlowSettingsData: Entry<IBeSpaFlowFields>[]
) => {
  const comfortFlowSettings = getSpaFlowSettingsByAdmissionType(
    'comfort',
    spaFlowSettingsData
  )
  const premiumFlowSettings = getSpaFlowSettingsByAdmissionType(
    'premium',
    spaFlowSettingsData
  )

  return (
    premiumFlowSettings?.fields.metadata || comfortFlowSettings?.fields.metadata
  )
}

// Get the Klaviyo newsletter id and fallback to the english one if the icelandic one is not set
export const getSpaNewsletterId = (
  spaFlowSettingEntry: Entry<IBeSpaFlowFields>,
  locale: string
) => {
  return locale === 'en'
    ? spaFlowSettingEntry?.fields?.newsletterId
    : spaFlowSettingEntry?.fields?.newsletterIdIcelandic ||
        spaFlowSettingEntry?.fields?.newsletterId
}

export const getSpaComfortOrPremiumAlertBanner = (
  spaFlowSettingsData: Entry<IBeSpaFlowFields>[]
) => {
  const comfortFlowSettings = getSpaFlowSettingsByAdmissionType(
    'comfort',
    spaFlowSettingsData
  )
  const premiumFlowSettings = getSpaFlowSettingsByAdmissionType(
    'premium',
    spaFlowSettingsData
  )

  return (
    premiumFlowSettings?.fields.alertBanners ||
    comfortFlowSettings?.fields.alertBanners
  )
}
