import { useContext, useEffect, useMemo, useState } from 'react'
import { addMinutes } from 'date-fns/addMinutes'
import { isSameMinute } from 'date-fns/isSameMinute'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useTheme } from 'styled-components'

import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { IActivityCard } from 'bl-common/src/generated/contentful'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import {
  buildCardButtonField,
  buildModalField,
  FieldRenderer,
  FlowComponent,
} from 'bl-flows-core'
import {
  ActivityItem,
  AdmissionItem,
  CartItemType,
  CartType,
  MassageTypeInfo,
  Product,
  useMassageAndFloatAvailabilityLazyQuery,
  useProductsAvailabilityLazyQuery,
  useProductsCatalogQuery,
} from 'bl-graphql'
import {
  productIdToItemListId,
  productIdToItemListName,
  productIdToName,
} from 'bl-utils/src/analytics'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { globalBookingMessages } from '../../../messages'
import {
  buildFloatFlow,
  buildLavaFlow,
  buildMassageFlow,
  buildSkincareFlow,
} from '../../../subflows'
import { buildSpaRestaurantFlow } from '../../../subflows/spaRestaurant'
import { areActivitiesOverlapping } from '../../../utils/areActivitiesOverlapping'
import { getIsActivityExceedingGuests } from '../../../utils/getIsActivityExceedingGuests'
import { getMassageOrFloatNames } from '../../../utils/getMassageOrFloatNames'
import { getSpaCartItinerary } from '../../../utils/getSpaCartItinerary'
import { isActivityInCart } from '../../../utils/isActivityInCart'

const ActivitiesWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
})

const cardVariant = {
  active: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  inactive: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.3,
    },
  },
}

export const Activities: React.FC<FlowComponent> = ({
  control,
  screenTheme,
}) => {
  const [massages, setMassages] = useState<MassageTypeInfo[]>()
  const [floats, setFloats] = useState<MassageTypeInfo[]>()
  const [noFloatsAvailable, setNoFloatsAvailable] = useState<boolean>()
  const [noMassagesAvailable, setNoMassagesAvailable] = useState<boolean>()
  const [productAvailability, setProductAvailability] = useState([])
  const [hasViewListItemTriggered, setHasViewListItemTriggered] =
    useState(false)

  const { cart, addProductCartItem } = useCartContext()
  const { exchangeRates } = useContext(CurrencyContext)
  const theme = useTheme()

  const activities = control.screen.setupHook?.activities as IActivityCard[]
  const isRetreatSpaFlow = control.flow.setupHook?.isRetreatSpaFlow
  const isBluelagoonSpaFlow = !isRetreatSpaFlow
  const enableAddGroupFloat = control.context?.enableAddGroupFloat

  // Not storing the result in cache, since it can interfer with the cache
  // of the other queries (inside MassageCard)
  const [fetchMassageAndFloatAvailability, { loading }] =
    useMassageAndFloatAvailabilityLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
    })

  const [fetchProductAvailability] = useProductsAvailabilityLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
  })

  const skincareProduct = useProductsCatalogQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    variables: {
      input: {
        type: CartType.Dayspa,
      },
    },
  }).data?.productsCatalog?.find(
    (product: Product) => product.productId === PRODUCT_IDS.SilicaMudMask75ml
  )

  const adults = control.flow.state.guests?.adults
  const children = control.flow.state.guests?.children ?? 0

  const admissionItem = cart?.items?.find(
    item => item.type === CartItemType.Admission
  ) as AdmissionItem
  const arrivalDate = new Date(admissionItem?.meta?.arrivalTime)

  const isSameDayBooking =
    arrivalDate.toLocaleDateString() === new Date().toLocaleDateString()

  // Only show cards that are marked in contentful that they should be shown in SPA

  const spaActivities = activities?.filter(activity => {
    if (
      isSameDayBooking &&
      !activity.fields.displayIn.includes('Allow on same day booking')
    )
      return false

    if (
      isRetreatSpaFlow &&
      !activity.fields.displayIn.includes('The Retreat Spa')
    )
      return false

    if (isBluelagoonSpaFlow && !activity.fields.displayIn?.includes('BL Spa'))
      return false

    return true
  })

  // check if there are already massages/floats in cart for all guests
  const isMassageExceedingGuests = getIsActivityExceedingGuests({
    guests: adults + children,
    cart,
  })
  const isFloatExceedingGuests = getIsActivityExceedingGuests({
    guests: adults + children,
    cart,
    isFloat: true,
  })

  // check if there are already activities for all guests
  // at the same time as the currently chosen massage/float time
  const allGuestsBusy = (time, productId) => {
    // check how many activities in cart overlap the currently chosen massage/float time
    const overlappingActivities = cart.items
      ?.filter(item => item.type === CartItemType.Massage)
      ?.filter((m: ActivityItem) =>
        areActivitiesOverlapping(m, { time, productId })
      )
    // if the number of overlapping activities equals the number of guests, then all guests are busy
    return overlappingActivities.length >= adults + children
  }

  useEffect(() => {
    if (!admissionItem) {
      return
    }
    const fetchAsync = async () => {
      const res = await fetchMassageAndFloatAvailability({
        variables: {
          locale: control.context.locale,
          date: formatDateInUTC(arrivalDate, 'yyyy-MM-dd'),
          arrivalTime: formatDateInUTC(
            new Date(admissionItem.meta.arrivalTime),
            'HH:mm'
          ),
        },
      })

      // We are splitting this into two queries because some products are time sensitive and some are not
      const productAvailabilityDataTimeSensitive =
        await fetchProductAvailability({
          variables: {
            input: {
              type: CartType.Dayspa,
              from: formattedFromTime,
              to: formattedToTime,
              productIds: [
                PRODUCT_IDS.Massage30,
                PRODUCT_IDS.Massage60,
                PRODUCT_IDS.Massage120,
                PRODUCT_IDS.MassageFloatingOne,
                PRODUCT_IDS.MassageFloatingTwo,
                PRODUCT_IDS.MassageFloatingMidnight,
                PRODUCT_IDS.MassageFloatingGroup,
              ],
            },
          },
        })

      const productAvailabilityDataNotTimeSensitive =
        await fetchProductAvailability({
          variables: {
            input: {
              type: CartType.Dayspa,
              from: isSameDayBooking
                ? formattedFromTime
                : setDateTimeISOString(date, '00:00'),
              to: formattedToTime,
              productIds: [
                PRODUCT_IDS.RestaurantLava,
                PRODUCT_IDS.SpaRestaurant,
              ],
            },
          },
        })

      control.screen.setUiState({
        productAvailabilityLoaded: true,
      })

      const timeSensitiveAvailabilities =
        productAvailabilityDataTimeSensitive?.data?.productAvailabilities || []
      const notTimeSensitiveAvailabilities =
        productAvailabilityDataNotTimeSensitive?.data?.productAvailabilities ||
        []

      const combinedProductAvailabilities = [
        ...timeSensitiveAvailabilities,
        ...notTimeSensitiveAvailabilities,
      ]

      setProductAvailability(combinedProductAvailabilities)

      setMassages(res.data?.massageAndFloatAvailability?.massages || [])
      setFloats(res.data?.massageAndFloatAvailability?.floats || [])
    }

    fetchAsync()
  }, [admissionItem])

  // Check if floats or massages have no availability, only when floats or massages change
  useEffect(() => {
    // We only want to update the variables if floats and massages are not undefined
    // sometimes floats are still undefined when massages are ready or the other way around
    // which is why they have seperate if checks
    if (floats) {
      setNoFloatsAvailable(!floats?.some(float => float.available > 0))
    }
    if (massages) {
      setNoMassagesAvailable(!massages?.some(massage => massage.available > 0))
    }
  }, [massages, floats])

  const triggerAddToCartEvent = (options: {
    productId: string
    price: number
    discount?: number
    quantity?: number
  }) => {
    const priceWithDiscount = options.price - (options.discount ?? 0)
    const itemId =
      options.productId === PRODUCT_IDS.SilicaMudMask75ml
        ? 'skincare'
        : options.productId === PRODUCT_IDS.RestaurantLava
        ? 'lava'
        : options.productId

    try {
      triggerEvent({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: options.price
            ? calcPrice(
                priceWithDiscount,
                control.flow?.setupHook?.exchangeRates?.EUR
              ) * (options.quantity ?? 1)
            : 0,
          items: [
            {
              item_id: itemId,
              item_name: productIdToName[options.productId],
              currency: 'EUR',
              discount: options.discount
                ? calcPrice(
                    options.discount,
                    control.flow?.setupHook?.exchangeRates?.EUR
                  )
                : 0,
              item_brand: 'Blue Lagoon',
              item_category: 'Day Visit',
              item_list_id: productIdToItemListId[options.productId],
              item_list_name: productIdToItemListName[options.productId],
              price: options.price
                ? calcPrice(
                    options.price,
                    control.flow?.setupHook?.exchangeRates?.EUR
                  )
                : 0,
              quantity: options.quantity ?? 1,
            },
          ],
        },
      })
    } catch (err) {
      sentryLogging({
        error: new Error(
          'Error triggering add_to_cart Google Analytics event',
          err
        ),
      })
    }
  }
  useEffect(() => {
    if (
      spaActivities?.length > 0 &&
      skincareProduct &&
      massages !== undefined &&
      floats !== undefined &&
      exchangeRates &&
      !hasViewListItemTriggered
    ) {
      setHasViewListItemTriggered(true)
      try {
        triggerEvent({
          event: 'view_item_list',
          ecommerce: {
            item_list_id: 'extras',
            item_list_name: 'Extras',
            items: spaActivities.map((activity, index) => {
              const price = getLowestPriceForActivity(activity)

              return {
                item_id: activity.fields?.flowId,
                item_name: activity.fields?.title,
                currency: 'EUR',
                index,
                item_brand: 'Blue Lagoon',
                item_category: 'Day Visit',
                item_list_name: 'Extras',
                item_list_id: 'extras',
                price: price ? calcPrice(price, exchangeRates?.EUR) : 0,
                quantity: 1,
              }
            }),
          },
        })
      } catch (err) {
        sentryLogging({
          error: new Error(
            'Error triggering view_item_list Google Analytics event',
            err
          ),
        })
      }
    }
  }, [spaActivities, exchangeRates, skincareProduct, massages])

  const triggerViewItemEvent = (activity, index) => {
    const lowestPrice = getLowestPriceForActivity(activity)
    const discountPrice =
      activity.fields?.flowId === 'skincare'
        ? skincareProduct.discountedPrice
        : 0

    const price = lowestPrice ? calcPrice(lowestPrice, exchangeRates?.EUR) : 0

    const discount = calcPrice(lowestPrice - discountPrice, exchangeRates?.EUR)

    const value = price - discount

    try {
      triggerEvent({
        event: 'view_item',
        ecommerce: {
          currency: 'EUR',
          value,
          items: [
            {
              item_id: activity.fields?.flowId,
              item_name: activity.fields?.title,
              currency: 'EUR',
              index,
              item_brand: 'Blue Lagoon',
              item_category: 'Day Visit',
              item_list_name: 'Extras',
              item_list_id: 'extras',
              price,
              quantity: 1,
              discount,
            },
          ],
        },
      })
    } catch (err) {
      sentryLogging({
        error: new Error(
          'Error triggering view_item Google Analytics event',
          err
        ),
      })
    }
  }

  const floatFlow = useMemo(() => {
    return buildFloatFlow(
      async (_, floatFlowControl) => {
        const floats = floatFlowControl?.flow.stateRef?.current?.time?.massages

        try {
          for (let i = 0; i < floats.length; i++) {
            const isLastFloat = i === floats.length - 1
            await addMassageOrFloat(floats[i], isLastFloat)
          }

          return true
        } catch (err) {
          console.error(err)
          return false
        }
      },
      floatFlowControl => {
        const parentFlowControl = floatFlowControl.parentFlowControl

        parentFlowControl.screen.setUiState({
          showFloatFlow: false,
        })

        floatFlowControl.reset()
      },
      {
        bookingInfo: {
          entryDate: arrivalDate,
          adults,
          children,
          spa: 'spa',
        },
        activities: [],
        transportation: {},
        itineraryItems: getSpaCartItinerary(
          cart?.items || [],
          control.context.t
        ),
        isSPA: true,
        isRetreatSpaFlow,
        enableAddGroupFloat,
        isFloatExceedingGuests,
        allGuestsBusy,
        noFloatsAvailable,
      }
    )
  }, [cart, control.screen.uiState?.showFloatFlow, noFloatsAvailable])

  const massageFlow = useMemo(() => {
    return buildMassageFlow(
      async (_, massageFlowControl) => {
        const massages =
          massageFlowControl?.flow.stateRef?.current?.time?.massages

        try {
          for (let i = 0; i < massages.length; i++) {
            const isLastMassage = i === massages.length - 1
            await addMassageOrFloat(massages[i], isLastMassage)
          }

          return true
        } catch (err) {
          console.error(err)
          return false
        }
      },
      massageFlowControl => {
        const parentFlowControl = massageFlowControl.parentFlowControl

        parentFlowControl.screen.setUiState({
          showMassageFlow: false,
        })

        massageFlowControl.reset()
      },
      {
        bookingInfo: {
          entryDate: arrivalDate,
          adults,
          children,
          spa: 'spa',
        },
        activities: [],
        transportation: {},
        itineraryItems: getSpaCartItinerary(
          cart?.items || [],
          control.context.t
        ),
        isSPA: true,
        isRetreatSpaFlow,
        isMassageExceedingGuests,
        allGuestsBusy,
        noMassagesAvailable,
      }
    )
  }, [cart, control.screen.uiState?.showMassageFlow, noMassagesAvailable])

  // don't move this lower in the file, it needs to be above the time formatters below
  // because otherwise the page crashes because of trying to format Invalid Date
  if (!admissionItem) {
    return (
      <SpinnerWrapper style={{ marginBottom: 20 }}>
        <Spinner shouldAnimate />
      </SpinnerWrapper>
    )
  }

  // Make sure that we always have a valid date
  // fromTime should never be in the past, otherwise we get an error from the API.
  // Adding 5 minutes to the current time as a buffer.
  const fromTime = formatDateInUTC(addMinutes(arrivalDate, 5), 'HH:mm')

  const toTime = '23:59'

  // Format the date
  const date = formatDateInUTC(arrivalDate, 'yyyy-MM-dd')

  // Format the date with the time
  const formattedFromTime = setDateTimeISOString(date, fromTime)
  const formattedToTime = setDateTimeISOString(date, toTime)

  const addMassageOrFloat = async (massage, isLastMassage) => {
    const date = formatDateInUTC(massage.date)
    const time = massage.time as string
    const dateTimeISOString = setDateTimeISOString(date, time)

    const product = productAvailability.find(item => {
      return (
        item.productId === massage.productNo &&
        isSameMinute(new Date(item.time), new Date(dateTimeISOString))
      )
    })

    const offerId = product?.offerId

    if (!offerId) {
      sentryLogging({
        error: new Error(
          `(addMassageOrFloat): OfferId not found for product ${massage.productNo} and time ${dateTimeISOString}`
        ),
      })
      return false
    }

    await addProductCartItem(
      {
        cartId: cart?.id,
        productId: massage.productNo,
        qty: massage.quantity || 1,
        type: CartType.Dayspa,
        meta: {
          arrivalTime: dateTimeISOString,
          offerId,
          ...(!!massage?.guestName && {
            comment: getMassageOrFloatNames(massage),
          }),
        },
      },
      !isLastMassage
    )

    triggerAddToCartEvent({
      productId: massage.productNo,
      quantity: massage.quantity ?? 1,
      price: massage.massageInfo?.price ?? 0,
    })
  }

  const getLowestPrice = (massages: MassageTypeInfo[]) => {
    return massages?.reduce((lowest, curr) => {
      if (lowest) {
        return lowest < curr.price ? lowest : curr.price
      }
      return curr.price
    }, 0)
  }

  const getLowestPriceForActivity = activity => {
    return activity.fields?.flowId === 'massage'
      ? getLowestPrice(massages)
      : activity?.fields?.flowId === 'float'
      ? getLowestPrice(floats)
      : activity?.fields?.flowId === 'skincare'
      ? skincareProduct?.price
      : 0
  }

  return (
    <>
      {spaActivities?.map((activity, i) => {
        const isFloat = activity.fields?.flowId === 'float'
        const isMassage = activity.fields?.flowId === 'massage'

        const priceFormat = loading ? '' : activity.fields?.priceFormat

        const priceValue =
          (isFloat && noFloatsAvailable) || (isMassage && noMassagesAvailable)
            ? control.context.t(globalBookingMessages.text.notAvailable)
            : getLowestPriceForActivity(activity)

        return (
          <motion.li key={`activity-${i}`} variants={cardVariant}>
            <ActivitiesWrapper>
              <FieldRenderer
                control={control}
                screenTheme={screenTheme}
                item={buildCardButtonField({
                  props: {
                    themeStyle:
                      theme.bookingEngine[screenTheme]?.cardButtonField,
                    title: activity.fields?.title,
                    description: activity.fields?.subtitle,
                    ctaLabel: activity.fields?.cta,
                    image: activity.fields?.image,
                    priceFormat,
                    price: !loading && priceValue,
                    linePrice:
                      activity?.fields?.flowId === 'skincare' &&
                      skincareProduct?.discountedPrice,
                    isClosed: false,
                    focusCardLabel: activity.fields?.focusCardLabel,
                    onClick: control => {
                      triggerViewItemEvent(activity, i)

                      const flowId = activity.fields?.flowId

                      control.screen.setUiState({
                        ...(flowId === 'lava' && {
                          showLavaFlow: true,
                        }),
                        ...(flowId === 'massage' && {
                          showMassageFlow: true,
                        }),
                        ...(flowId === 'float' && {
                          showFloatFlow: true,
                        }),
                        ...(flowId === 'skincare' && {
                          showSkincareFlow: true,
                        }),
                        ...(flowId === 'spa-restaurant' && {
                          showSpaRestaurantFlow: true,
                        }),
                      })
                    },
                    isDisabled: false,
                    disclaimer: 'Disclaimer',
                    inCart: isActivityInCart(control, activity.fields?.flowId),
                    inCartLabel: control.context.t(
                      globalBookingMessages.labels.inCart
                    ),
                  },
                  layout: {
                    spacing: {
                      mb: { xs: 2 },
                    },
                  },
                })}
              />
            </ActivitiesWrapper>
          </motion.li>
        )
      })}

      {/* Lava Flow */}
      <FieldRenderer
        control={control}
        item={buildModalField({
          props: {
            isOpen: control => control.screen.uiState?.showLavaFlow,
            onHide: (_, control) => {
              control.screen.setUiState({
                showLavaFlow: false,
              })
            },
          },
          children: () =>
            buildLavaFlow(
              async (_, lavaFlowControl) => {
                const date = formatDateInUTC(
                  new Date(lavaFlowControl?.flow?.state?.time.date),
                  'yyyy-MM-dd'
                )
                const time = lavaFlowControl?.flow?.state?.time?.time as string
                const dateTimeISOString = setDateTimeISOString(date, time)

                const product = productAvailability.find(item => {
                  return (
                    item.productId === PRODUCT_IDS.RestaurantLava &&
                    isSameMinute(
                      new Date(item.time),
                      new Date(dateTimeISOString)
                    )
                  )
                })

                const offerId = product?.offerId

                if (!offerId) {
                  sentryLogging({
                    error: new Error(
                      `(Lava flow): OfferId not found for product ${PRODUCT_IDS.RestaurantLava} and time ${dateTimeISOString}`
                    ),
                  })
                  return false
                }

                try {
                  await addProductCartItem({
                    cartId: cart?.id,
                    productId: PRODUCT_IDS.RestaurantLava,
                    qty: 1,
                    meta: {
                      arrivalTime: dateTimeISOString,
                      noOfPersons:
                        lavaFlowControl?.flow.stateRef?.current?.guests?.guests,
                      offerId,
                      ...(lavaFlowControl?.flow.stateRef?.current?.guests
                        ?.comment && {
                        comment:
                          lavaFlowControl?.flow.stateRef?.current?.guests
                            ?.comment,
                      }),
                    },
                  })

                  triggerAddToCartEvent({
                    productId: PRODUCT_IDS.RestaurantLava,
                    quantity:
                      lavaFlowControl?.flow.stateRef?.current?.guests?.guests ||
                      1,
                    price: product?.price ?? 0,
                  })

                  return true
                } catch (err) {
                  console.error(err)
                  return false
                }
              },
              lavaFlowControl => {
                const parentFlowControl = lavaFlowControl.parentFlowControl

                parentFlowControl.screen.setUiState({
                  showLavaFlow: false,
                })

                lavaFlowControl.reset()
              },
              {
                bookingInfo: {
                  entryDate: arrivalDate,
                  adults,
                  children,
                  spa: 'spa',
                },
                activities: [],
                transportation: {},
                itineraryItems: getSpaCartItinerary(
                  cart?.items || [],
                  control.context.t
                ),
                isSPA: true,
                isRetreatSpaFlow,
              }
            ),
        })}
      />

      {/* Spa Restaurant Flow */}
      <FieldRenderer
        control={control}
        item={buildModalField({
          props: {
            isOpen: control => control.screen.uiState?.showSpaRestaurantFlow,
            onHide: (_, control) => {
              control.screen.setUiState({
                showSpaRestaurantFlow: false,
              })
            },
          },
          children: () =>
            buildSpaRestaurantFlow(
              async (_, spaRestaurantFlowControl) => {
                const date = formatDateInUTC(
                  new Date(spaRestaurantFlowControl?.flow?.state?.time.date),
                  'yyyy-MM-dd'
                )
                const time = spaRestaurantFlowControl?.flow?.state?.time
                  ?.time as string
                const dateTimeISOString = setDateTimeISOString(date, time)

                const product = productAvailability.find(item => {
                  return (
                    item.productId === PRODUCT_IDS.SpaRestaurant &&
                    isSameMinute(
                      new Date(item.time),
                      new Date(dateTimeISOString)
                    )
                  )
                })

                const offerId = product?.offerId

                if (!offerId) {
                  sentryLogging({
                    error: new Error(
                      `(Spa Restaurant Flow): OfferId not found for product ${PRODUCT_IDS.SpaRestaurant} and time ${dateTimeISOString}`
                    ),
                  })
                  return false
                }

                try {
                  await addProductCartItem({
                    cartId: cart?.id,
                    productId: PRODUCT_IDS.SpaRestaurant,
                    qty: 1,
                    meta: {
                      arrivalTime: dateTimeISOString,
                      noOfPersons:
                        spaRestaurantFlowControl?.flow.stateRef?.current?.guests
                          ?.guests,
                      offerId,
                      ...(spaRestaurantFlowControl?.flow.stateRef?.current
                        ?.guests?.comment && {
                        comment:
                          spaRestaurantFlowControl?.flow.stateRef?.current
                            ?.guests?.comment,
                      }),
                    },
                  })

                  triggerAddToCartEvent({
                    productId: PRODUCT_IDS.SpaRestaurant,
                    quantity:
                      spaRestaurantFlowControl?.flow.stateRef?.current?.guests
                        ?.guests || 1,
                    price: product?.price ?? 0,
                  })

                  return true
                } catch (err) {
                  console.error(err)
                  return false
                }
              },
              spaRestaurantFlowControl => {
                const parentFlowControl =
                  spaRestaurantFlowControl.parentFlowControl

                parentFlowControl.screen.setUiState({
                  showSpaRestaurantFlow: false,
                })

                spaRestaurantFlowControl.reset()
              },
              {
                bookingInfo: {
                  entryDate: arrivalDate,
                  adults,
                  children,
                  spa: 'spa',
                },
                activities: [],
                transportation: {},
                itineraryItems: getSpaCartItinerary(
                  cart?.items || [],
                  control.context.t
                ),
                isSPA: true,
                isRetreatSpaFlow,
              }
            ),
        })}
      />

      {/* Massage Flow */}
      <FieldRenderer
        control={control}
        item={buildModalField({
          props: {
            isOpen: control => control.screen.uiState?.showMassageFlow,
            onHide: (_, control) => {
              control.screen.setUiState({
                showMassageFlow: false,
              })
            },
          },
          children: massageFlow,
        })}
      />

      {/* Float Flow */}
      <FieldRenderer
        control={control}
        item={buildModalField({
          props: {
            isOpen: control => control.screen.uiState?.showFloatFlow,
            onHide: (_, control) => {
              control.screen.setUiState({
                showFloatFlow: false,
              })
            },
          },
          children: floatFlow,
        })}
      />

      {/* Skincare Flow */}
      <FieldRenderer
        control={control}
        item={buildModalField({
          props: {
            isOpen: control => control.screen.uiState?.showSkincareFlow,
            onHide: (_, control) => {
              control.screen.setUiState({
                showSkincareFlow: false,
              })
            },
          },
          children: () =>
            buildSkincareFlow(
              async (_, skincareFlowControl) => {
                try {
                  await addProductCartItem({
                    cartId: cart?.id,
                    productId: PRODUCT_IDS.SilicaMudMask75ml,
                    qty: skincareFlowControl.screen?.stateRef?.current?.[
                      PRODUCT_IDS.SilicaMudMask75ml
                    ],
                  })

                  triggerAddToCartEvent({
                    productId: PRODUCT_IDS.SilicaMudMask75ml,
                    quantity:
                      skincareFlowControl.screen?.stateRef?.current?.[
                        PRODUCT_IDS.SilicaMudMask75ml
                      ],
                    price: skincareProduct?.price,
                    discount:
                      skincareProduct?.price - skincareProduct?.discountedPrice,
                  })

                  return true
                } catch (err) {
                  console.error(err)
                  return false
                }
              },
              skincareFlowControl => {
                const parentFlowControl = skincareFlowControl.parentFlowControl

                parentFlowControl.screen.setUiState({
                  showSkincareFlow: false,
                })

                skincareFlowControl.reset()
              },
              {
                isRetreatSpaFlow,
              }
            ),
        })}
      />
    </>
  )
}
