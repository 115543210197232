import { Cart } from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { getSpaBookingItems } from '../../../utils/get-analytics-items'
import { orderAdmissions } from './ordered-admissions'

export const triggerAddShippingEvent = control => {
  const exchangeRates = control.flow.setupHook?.exchangeRates
  const cart = control.flow.setupHook?.cart as Cart
  const orderedAdmissions = orderAdmissions(cart)
  const analyticsItems = getSpaBookingItems(
    orderedAdmissions,
    exchangeRates,
    cart?.promoCode ?? ''
  )

  try {
    triggerEvent({
      event: 'add_shipping_info',
      ecommerce: {
        coupon: cart?.promoCode ?? '',
        currency: 'EUR',
        value:
          calcPrice(
            cart?.paymentAmount ?? cart?.totalAmount,
            exchangeRates.EUR
          ) ?? 0,
        items: analyticsItems,
      },
    })
  } catch (err) {
    sentryLogging({
      error: new Error(
        'Error triggering add_shipping_info Google Analytics event',
        err
      ),
    })
  }
}
